import {baseApi} from "../baseApi";

const paymentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCheckout: builder.mutation({
      query: (checkoutData) => ({
        url: "/checkout/new",
        method: "POST",
        data: checkoutData,
      }),
      invalidatesTags: ["checkout","cart"],
    }),
    createPayment: builder.mutation({
      query: (paymentData) => ({
        url: "/payment/new",
        method: "POST",
        data: paymentData,
      }),
      invalidatesTags: ["payment"],
    }),
    getCheckoutByID: builder.query({
      query: (id) => ({
        url: `/checkout/${id}`,
        method: "GET",
      }),
      providesTags: ["checkout"],
    }),
  }),
});

export const {useCreateCheckoutMutation, useCreatePaymentMutation, useGetCheckoutByIDQuery} = paymentApi;
