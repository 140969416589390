import React from "react";

const Loader = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
      className="d-flex  justify-content-center align-items-center gap-1 "
    >
     
      <div
        className="spinner-grow"
        style={{ width: "1rem", height: "1rem" }}
        role="status"
      >
     
      </div>
      <div
        className="spinner-grow"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      >
       
      </div>
      <div
        className="spinner-grow"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
  
      </div>
    </div>
  );
};

export default Loader;
