import React, {useEffect, useState} from "react";
import {useRouter} from "next/router";
import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";

import {useDispatch, useSelector} from "react-redux";
import {useAppContext} from "@/context/Context";
import {addToCart} from "@/redux/api/cart/cartSlice";
import {
  useGetSingleUserCartQuery,
  useRemoveFromCartMutation,
} from "@/redux/api/cart/cartApi";
import {toast} from "react-toastify";
import {useCreateCheckoutMutation} from "@/redux/api/payment/paymentApi";
import Loader from "@/components/Loader/Loader";


const Cart = () => {
  const router = useRouter();
  const [createCheckout, {isLoading: checkoutLoading}] =
    useCreateCheckoutMutation();
  const [skip, setSkip] = useState(true);

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const {data, isLoading} = useGetSingleUserCartQuery(undefined, {skip});
  const {cartToggle, setCart} = useAppContext();
  const [removeFromCart] = useRemoveFromCartMutation();

  useEffect(() => {
    const tokenString = localStorage.getItem("persist:auth");
    const tokenObj = JSON.parse(tokenString)?.token;

    if (tokenObj && tokenObj !== "null" && tokenObj !== "") {
      setSkip(false);
    } else {
      addToCart({total: 0, product: [], totalItems: 0});
      setSkip(true);
    }

    dispatch(
      addToCart({
        total: data?.data?.totalCartAmount,
        product: data?.data?.carts,
        totalItems: data?.data?.totalCartItem,
        totalCourseDiscountAmount: data?.data?.totalDiscountAmount,
      })
    );
  }, [dispatch, data, skip]);

  if (isLoading) {
    return <Loader />;
  }

  const handleSubmit = async () => {
    const data = {
      ids: cart?.products?.map((product) => product?.id),
      totalAmount: cart?.total,
      totalPayableAmount: cart?.total - cart?.totalCourseDiscountAmount,
      totalDiscountAmount: cart?.totalCourseDiscountAmount,
    };

    const res = await createCheckout(data);
    if (res?.data?.success) {
      toast.success(res?.data?.message);
      router.push(`/checkout/${res?.data?.data?.id}`);
    }
  };

  const getPriceAfterDiscount = (newCart) => {
    if (newCart?.CourseBatch) {
      let discountAmount =
        (newCart?.CourseBatch?.discountPercentage / 100) *
        newCart?.CourseBatch?.price;

      return newCart.CourseBatch.price - discountAmount;
    } else if (newCart?.Quiz) {
      let discountAmount =
        (newCart?.Quiz?.discountPercentage / 100) * newCart?.Quiz?.price;

      return newCart?.Quiz?.price - discountAmount;
    } else if (newCart?.WrittenExam) {
      let discountAmount =
        (newCart?.WrittenExam?.discountPercentage / 100) *
        newCart?.WrittenExam?.price;

      return newCart?.WrittenExam?.price - discountAmount;
    } else {
      return newCart?.SubscriptionPlan?.price;
    }
  };

  return (
    <>
      <div className={`${!cartToggle ? "cart-sidenav-menu-active" : ""}`}>
        <div
          className={`rbt-cart-side-menu ${
            !cartToggle ? "side-menu-active" : ""
          }`}>
          <div className="inner-wrapper">
            <div className="inner-top">
              <div className="content">
                <div className="title">
                  <h4 className="title mb--0">Your Cart</h4>
                </div>
                <div className="rbt-btn-close" id="btn_sideNavClose">
                  <button
                    className="minicart-close-button rbt-round-btn"
                    onClick={() => setCart(!cartToggle)}>
                    <i className="feather-x"></i>
                  </button>
                </div>
              </div>
            </div>
            <nav className="side-nav w-100">
              <div className="rbt-minicart-wrapper">
                {cart &&
                  cart?.products?.map((data, index) => (
                    <li className="minicart-item" key={index}>
                      <div className="thumbnail">
                        <Image
                          src={`${process.env.NEXT_PUBLIC_FILE_BASE}${
                            data?.CourseBatch?.Courses?.thumbnail
                              ? data.CourseBatch.Courses.thumbnail
                              : data?.Quiz?.thumbnail
                              ? data.Quiz.thumbnail
                              : data?.SubscriptionPlan?.thumbnail
                              ? data.SubscriptionPlan.thumbnail
                              : data?.WrittenExam?.thumbnail
                          }`}
                          width={80}
                          height={64}
                          alt="Product Images"
                        />
                      </div>
                      <div className="product-content">
                        <h6 className="title">
                          {data?.CourseBatch
                            ? data.CourseBatch.Courses?.title
                            : data?.Quiz
                            ? data.Quiz?.title
                            : data?.SubscriptionPlan?.title
                            ? data.SubscriptionPlan.title
                            : data?.WrittenExam?.title}
                        </h6>

                        <span className="quantity">
                          <span className="price">
                            ৳ {getPriceAfterDiscount(data)}
                          </span>
                        </span>
                      </div>
                      <div className="close-btn">
                        <button
                          className="rbt-round-btn"
                          onClick={async () => {
                            const res = await removeFromCart({id: data?.id});

                            if (res?.data?.success === true) {
                              toast.success(res?.data?.message);
                            }
                          }}>
                          <i className="feather-x"></i>
                        </button>
                      </div>
                    </li>
                  ))}
              </div>
            </nav>

            <div className="rbt-minicart-footer">
              <hr className="mb--0" />
              <div className="rbt-cart-subttotal">
                <p className="subtotal">
                  <strong>Subtotal:</strong>
                </p>
                <p className="price">
                  ৳ {cart?.total - cart?.totalCourseDiscountAmount}
                </p>
              </div>
              <hr className="mb--0" />
              <div className="rbt-minicart-bottom mt--20">
                {/* <div className="view-cart-btn">
                  <Link
                    className="rbt-btn btn-border icon-hover w-100 text-center"
                    href="/cart">
                    <span className="btn-text">View Cart</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </Link>
                </div> */}
                <div className="checkout-btn mt--20">
                  <button
                    disabled={!cart?.products?.length}
                    role="button"
                    className="rbt-btn btn-gradient icon-hover w-100 text-center ">
                    {checkoutLoading ? (
                      <div>Loading...</div>
                    ) : (
                      <span
                        onClick={() => handleSubmit()}
                        className="btn-text  ">
                        Checkout
                      </span>
                    )}
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          className="close_side_menu"
          href="#"
          onClick={() => setCart(!cartToggle)}></Link>
      </div>
    </>
  );
};

export default dynamic(() => Promise.resolve(Cart), {ssr: false});
