import {baseApi} from "../baseApi";

const cartApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (cartData) => ({
        url: "/cart/new",
        method: "POST",
        data: cartData,
      }),
      invalidatesTags: ["cart"],
    }),
    getSingleUserCart: builder.query({
      query: () => ({
        url: "/cart",
        method: "GET",
      }),
      providesTags: ["cart"],
    }),
    removeFromCart: builder.mutation({
      query: ({id}) => ({
        url: `/cart/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cart"],
    }),
  }),
});

export const {
  useAddToCartMutation,
  useGetSingleUserCartQuery,
  useRemoveFromCartMutation,
} = cartApi;
